<template>
  <div class="container" v-if="data">
    <div>
      <Menu />
    </div>
    <div class="flex items-start justify-between mt-4">
      <div class="mt-4 px-4">
        <div class="border border-nColorBorder bg-nColorTextInput rounded-3xl">
          <div class="flex justify-between" v-if="data.pricing">
            <div class="grid p-4">
              <div class="w-32">
                <div
                  class="bg-transparent text-nColorLightGray border border-nColorLightGray rounded-2xl font-InterSemiBold text-sm px-6 py-0.5 capitalize text-center"
                >
                  {{ data.pricing[0].name }}
                </div>
              </div>
              <div v-if="data.features">
                <div>
                  <div
                    v-for="feature in data.features"
                    :key="feature.slug"
                    class="my-4"
                  >
                    <p class="font-InterSemiBold text-sm text-nColorLightGray">
                      {{ feature.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(pricing, index) in data.pricing"
              :key="index"
              class="grid text-center cursor-pointer px-10 my-6 w-1/3"
              @click.prevent="selectPricing(index)"
            >
              <div class="flex justify-center font-InterBold text-white">
                <span v-if="isNumber(pricing.price)">&#8377;</span
                >{{ pricing.price }}
              </div>
              <div>
                <div
                  v-for="feature in data.features"
                  :key="feature.slug"
                  class="my-4 flex justify-center"
                >
                  <div v-if="pricing[feature.slug] === 'Yes'">
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/diy/plan_page_green_tick.webp"
                      alt="Playda Pricing Feature Yes"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div v-else-if="pricing[feature.slug] === 'No'">
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/diy/plan_page_red_cross.webp"
                      alt="Playda Pricing Feature Yes"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div v-else class="text-nColorLightGray">
                    {{ pricing[feature.slug] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-2 items-center justify-end mt-4">
          <div
            class="bg-nColorTextInput flex gap-2 justify-between items-center p-3 rounded-3xl w-full h-16"
            v-if="couponApplied"
          >
            <div class="text-nColorYellow font-InterBold px-2">
              {{ code }}
            </div>
            <div>
              <button
                class="font-InterSemiBold px-4 py-1 rounded-3xl text-sm border border-nColorYellow text-nColorYellow bg-nColorTextInput"
                @click.prevent="removeCouponCode()"
              >
                Remove
              </button>
            </div>
          </div>
          <div
            class="bg-nColorTextInput flex gap-2 items-center p-3 rounded-3xl w-full h-16"
            v-else
          >
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/enter_promo_code.webp"
                alt="Enter Coupon Code"
                width="28"
                height="28"
              />
            </div>
            <div>
              <input
                type="text"
                name="code"
                id="code"
                v-model="code"
                class="textInput w-60"
                placeholder="Enter coupon code"
              />
            </div>
            <div>
              <button
                class="font-InterSemiBold px-4 py-1 rounded-3xl text-sm border border-nColorYellow text-nColorYellow bg-nColorTextInput"
                @click.prevent="applyCouponCode()"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <div class="h-10">
          <div v-if="codeInvalid" class="flex gap-1 items-center mx-2 pt-2">
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/coupon-invalid.webp"
                alt="Invalid Coupon Code"
                width="20"
                height="20"
              />
            </div>
            <p class="text-nColorRed font-InterRegular text-sm">
              This coupon is invalid
            </p>
          </div>
          <div v-if="codeValid" class="flex items-center">
            <div>
              <lottie-animation
                path="lotties/coupon-success.json"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                :width="35"
                :height="35"
              />
            </div>
            <p class="text-nColorSuccessGreen font-InterMedium text-xs">
              {{ validMsg }}
            </p>
          </div>
        </div>
        <div class="w-full p-4 font-InterSemiBold text-xs text-white">
          <div
            class="flex justify-between items-center border-b border-nColorBorder py-2"
          >
            <div>Subtotal</div>
            <div>
              <div class="flex justify-center font-InterBold">
                <span v-if="isNumber(summary.subTotal)">&#8377;</span
                >{{ summary.subTotal }}
              </div>
            </div>
          </div>
          <div
            class="flex justify-between items-center border-b border-nColorBorder py-2"
          >
            <div>Taxes</div>
            <div>
              <div class="flex justify-center font-InterBold">
                <span>&#8377;</span>{{ summary.taxes }}
              </div>
            </div>
          </div>
          <div
            class="flex justify-between items-center border-b border-nColorBorder py-2"
            v-if="summary.discount != 0"
          >
            <div>Discount</div>
            <div>
              <div class="flex justify-center font-InterBold">
                <span>&#8377;</span>{{ summary.discount }}
              </div>
            </div>
          </div>
          <div
            class="flex justify-between items-center border-b border-nColorBorder py-2"
          >
            <div>Total</div>
            <div>
              <div class="flex justify-center font-InterBold">
                <span v-if="isNumber(summary.total)">&#8377;</span
                >{{ summary.total }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-2 items-center mt-2 mx-4 mb-6">
      <button
        @click.prevent="changePlan()"
        class="border border-nColorYellow w-full rounded-3xl px-8 py-1 text-nColorYellow text-center uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none"
      >
        <span> Change plan </span>
      </button>
      <button
        @click.prevent="initiatePayment()"
        class="bg-nColorYellow rounded-3xl w-full px-8 py-1 text-nColorButtonText text-center uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        v-if="summary.total != 0"
      >
        <span> Pay now </span>
      </button>
      <button
        @click.prevent="continueWithoutPayment()"
        class="bg-nColorYellow rounded-3xl w-full px-8 py-1 text-nColorButtonText text-center uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        v-else
      >
        <span> Continue </span>
      </button>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { ref } from "vue";
import TokenService from "@/common/token.service";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { useToast } from "vue-toastification";
import Menu from "@/components/Elements/MobileMenu.vue";

const toast = useToast();

export default {
  name: "Desktop",
  components: { LottieAnimation, Menu },
  data() {
    return {
      data: null,
      selectedPricing: 0,
      isSubmitLoading: false,
      marketingURL: process.env.VUE_APP_MARKETING_URL,
      contactURL: process.env.VUE_APP_CONTACT_URL,
      scriptUrl: process.env.VUE_APP_RAZORPAY_URL,
      receiptId: null,
      orderDetails: null,
      finalPayment: null,
      code: null,
      codeInvalid: false,
      codeValid: false,
      validMsg: null,
      summary: {
        subTotal: null,
        taxes: 0,
        discount: 0,
        total: null,
      },
      couponApplied: false,
    };
  },
  created() {
    ApiService.post(apiResource.checkPricingForUser, {
      pricing_plan: TokenService.getToken("pricing_plan"),
    }).then((data) => {
      if (TokenService.getToken("pricing_plan") === "free_trial") {
        this.$router.replace({
          name: "Dashboard",
        });
      } else {
        this.data = data.data.data;
        this.summary.subTotal = this.summary.total = this.data.pricing[0].price;
      }
    });
  },
  methods: {
    async loadRazorpayScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = this.scriptUrl;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          reject(new Error("Failed to load Razorpay script"));
        };
        document.body.appendChild(script);
      });
    },
    async fetchOrderDetails() {
      this.finalPayment = this.summary.total * 100;
      await ApiService.post(apiResource.gameJams.createRazorpayOrder, {
        amt: this.finalPayment,
        summary: this.summary,
        code: this.code,
        coupon_applied: this.couponApplied,
        pricing_plan: TokenService.getToken("pricing_plan"),
      }).then((data) => {
        this.orderDetails = data.data.data;
        this.receiptId = this.orderDetails.receipt_id;
      });
    },
    async initiatePayment() {
      try {
        await this.loadRazorpayScript();
        await this.fetchOrderDetails();
        const options = {
          key: process.env.VUE_APP_RAZORPAY_KEY_ID,
          amount: this.finalPayment,
          currency: "INR",
          name: "Playda!",
          description: "Playda Game Jam Package - " + this.data.pricing[0].name,
          order_id: this.orderDetails.razorpay_order_response.id,
          image:
            "https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp",
          handler: (response) => {
            this.handlePaymentSuccess(response);
          },
          prefill: {
            name: this.orderDetails.diy_user_name,
            email: this.orderDetails.diy_user_email,
            contact: "",
          },
        };
        const razorpayInstance = new window.Razorpay(options);
        razorpayInstance.open();
      } catch (error) {
        console.log(error.message);
      }
    },
    handlePaymentSuccess(response) {
      ApiService.post(apiResource.gameJams.confirmPayment, {
        receipt_id: this.receiptId,
        payment_response: response,
        pricing_plan: TokenService.getToken("pricing_plan"),
      }).then(() => {
        toast.success("Payment received successfully!", { timeout: 2000 });
        setTimeout(() => {
          this.$router.replace({
            name: "Dashboard",
          });
        }, 2000);
      });
    },
    getBackgroundColorClass(index) {
      const classes = [
        "bg-diy-blue-bg",
        "bg-diy-green-bg",
        "bg-diy-pink-bg",
        "bg-diy-purple-bg",
      ];
      return classes[index % classes.length];
    },
    getTextColorClass(index) {
      const classes = [
        "text-diy-blue-text",
        "text-diy-green-text",
        "text-diy-pink-text",
        "text-diy-purple-text",
      ];
      return classes[index % classes.length];
    },
    selectPricing(index) {
      this.selectedPricing = index;
    },
    getStarted() {
      this.isSubmitLoading = true;
      const pricingArray = ref(this.data.pricing);
      var pricingDetails = pricingArray.value[this.selectedPricing];
      if (pricingDetails.action === "form") {
        window.location.replace(this.contactURL);
      } else {
        TokenService.saveToken("pricing_plan", pricingDetails.slug);
        this.$router.push({
          name: "Register",
        });
      }
    },
    isNumber(value) {
      return typeof value === "number";
    },
    signOut() {
      this.$store
        .dispatch("signOut")
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {});
    },
    changePlan() {
      this.$router.push({ name: "Pricing" });
    },
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    applyCouponCode() {
      this.codeInvalid = false;
      this.codeValid = false;
      ApiService.post(apiResource.applyCouponCode, {
        pricing_plan: TokenService.getToken("pricing_plan"),
        code: this.code,
      })
        .then((data) => {
          var dataResponse = data.data.data;
          this.codeValid = true;
          this.validMsg = dataResponse.message;
          this.summary.subTotal = dataResponse.sub_total;
          this.summary.taxes = dataResponse.taxes;
          this.summary.discount = dataResponse.discount;
          this.summary.total = dataResponse.total;
          this.couponApplied = true;
        })
        .catch(() => {
          this.codeInvalid = true;
        });
    },
    removeCouponCode() {
      this.summary.subTotal = this.data.pricing[0].price;
      this.summary.taxes = 0;
      this.summary.discount = 0;
      this.summary.total = this.data.pricing[0].price;
      this.couponApplied = false;
      this.codeInvalid = false;
      this.codeValid = false;
      this.code = null;
    },
    continueWithoutPayment() {
      ApiService.post(apiResource.continueWithoutPayment, {
        pricing_plan: TokenService.getToken("pricing_plan"),
        code: this.code,
        summary: this.summary,
      }).then(() => {
        this.$router.replace({
          name: "Dashboard",
        });
      });
    },
  },
  computed: {},
};
</script>

<style scoped lang="postcss">
.container {
  height: 100dvh;
}
.pricing-selected {
  @apply bg-nColorYellow border-l-4 border-r-4 border-nColorYellow;
}
.textInput {
  @apply w-full text-white pt-2 pb-3 focus:outline-none bg-nColorTextInput border-b border-nColorLightGray text-sm;
}
</style>
